import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import moment from 'moment'
import VeeValidate from 'vee-validate'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import './styles/app.scss'

Vue.config.productionTip = false

Vue.use(VeeValidate, {
  inject: true,
  fieldsBagName: 'veeFields',
  errorBagName: 'veeErrors',
  locale: 'zh_HK',
  dictionary: {
    zh_HK: {
      messages: {
        _default: (field) => `${field} 無效`,
        required: '請填寫所須資料'
      },
      custom: {
        image: {
          ext: () => '警告：系統只接受 jpg,jpeg,png 格式',
          image: () => '警告：系統只接受 jpg,jpeg,png 格式',
          size: () => '警告：系統只接受最大上傳檔案為1Mb'
        },
        sizeGuide: {
          ext: () => '警告：系統只接受 jpg,jpeg,png,pdf 格式',
          size: () => '警告: 系統只接受最大上傳檔案為15Mb'
        },
        descriptionZhHk: {
          max: '警告：字段不得超過 2000 個字符'
        },
        descriptionZhCn: {
          max: '警告：字段不得超過 2000 個字符'
        },
        price: {
          min_value: () => '價格必須大於零'
        },
        discountPercentage: {
          min_value: () => '折扣必須大於零',
          max_value: () => '折扣必須小於或等於 100'
        },
        validityPeriodDays: {
          integer: () => '有效期限（天）必須是整數'
        },
        baseDeliveryFee: {
          min_value: () => '外送費必須大於零'
        },
        threshold: {
          min_value: () => '閾值必須大於零'
        },
        refundAmount: {
          max_value: (field, [max]) => '退款金額不能大於最高金額'
        },
        driverTracking: {
          url: () => '無效的網址。請輸入有效的網址'
        }
      }
    }
  }
})

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

Vue.filter('formatDate', function (dateValue) {
  if (dateValue) {
    return moment(String(dateValue)).format('YYYY-MM-DD')
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
