import Vue from 'vue'
import Vuex from 'vuex'
import orderService from '@/services/order.service'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    sidebarState: false,
    orderList: []
  },

  mutations: {
    toggleSidebarState (state, data) {
      state.sidebarState = data
    },
    setOrderList (state, newData) {
      state.orderList = newData
    }
  },

  getters: {
    sidebarState: (state) => state.sidebarState,
    getOrderList: state => state.orderList
  },

  actions: {
    fetchData ({ commit }) {
      orderService.getAllOrder().then((response) => {
        commit('setOrderList', response.data)
      })
    }
  },

  modules: {
  }
})
