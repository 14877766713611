import Vue from 'vue'
import VueRouter from 'vue-router'
import AuthenticatedLayout from '@/layouts/AuthenticatedLayout'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: AuthenticatedLayout,
    redirect: '/dashboard',
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('@/views/Dashboard.vue')
      },
      {
        path: '/product',
        name: 'Product',
        component: () => import('@/views/Product/Index.vue')
      },
      {
        path: '/product-marketing',
        name: 'product-marketing',
        component: () => import('@/views/Marketing/Index.vue')
      },
      {
        path: '/product/:productId/inventory',
        name: 'Product-inventory',
        component: () => import('@/views/Product/InventoryView.vue')
      },
      {
        path: '/product/create',
        name: 'ProductCreate',
        component: () => import('@/views/Product/Create.vue')
      },
      {
        path: '/product/:productId/edit',
        name: 'ProductEdit',
        component: () => import('@/views/Product/Edit.vue')
      },
      {
        path: '/category',
        name: 'Category',
        component: () => import('@/views/Category.vue')
      },
      {
        path: '/feed',
        name: 'Feed',
        component: () => import('@/views/Feed.vue')
      },
      {
        path: '/gold-news',
        name: 'GoldNews',
        component: () => import('@/views/GoldNews.vue')
      },
      {
        path: '/rmbrate',
        name: 'RmbRate',
        component: () => import('@/views/RmbRate.vue')
      },
      {
        path: '/branch',
        name: 'Branch',
        component: () => import('@/views/Branch.vue')
      },
      {
        path: '/dispatcher/:status',
        name: 'order',
        component: () => import('@/views/Order/OrderView.vue')
      },
      {
        path: '/loyalty-programs',
        name: 'loyalty-programs',
        component: () => import('@/views/LoyaltyPrograms.vue')
      },
      {
        path: '/loyalty-programs/:couponId/coupon-details',
        name: 'coupon-details',
        component: () => import('@/components/coupons/CouponDetails.vue')
      },
      {
        path: '/loyalty-programs/create',
        name: 'loyalty-programs-create',
        component: () => import('@/components/coupons/CreateCoupon.vue')
      },
      {
        path: '/loyalty-programs/customers',
        name: 'loyalty-programs-customer',
        component: () => import('@/components/coupons/Customers/index.vue'),
        children: [
          {
            path: ':id/offer',
            name: 'loyalty-programs-offer',
            component: () => import('@/components/coupons/Customers/OfferToCustomer.vue')
          },
          {
            path: ':id/offer-for-birthdays',
            name: 'loyalty-programs-offer-for-birthdays',
            component: () => import('@/components/coupons/Customers/OfferToCustomerBirthday.vue')
          },
          {
            path: ':id/history',
            name: 'loyalty-programs-history',
            component: () => import('@/components/coupons/Customers/CouponHistory.vue')
          },
          {
            path: ':id/customer-using-coupon',
            name: 'loyalty-programs-customer-using-coupon',
            component: () => import('@/components/coupons/Customers/CustomerUsingCoupon.vue')
          }
        ]
      },
      {
        path: '/user-management',
        name: 'user-management',
        component: () => import('@/views/UserManagement.vue')
      },
      {
        path: '/user-management/:id/vouchers',
        name: 'user-management-vouchers',
        component: () => import('@/views/Customer/UserVouchers.vue')
      },
      {
        path: '/user-management/:id/order-history',
        name: 'user-management-order-history',
        component: () => import('@/views/Customer/OrderHistory.vue')
      },
      {
        path: '/settings',
        name: 'settings',
        component: () => import('@/views/SettingView.vue')
      }
    ]
  },
  {
    path: '/login',
    redirect: '/'
  },
  {
    path: '/*',
    name: 'NotFound',
    component: () => import('@/views/NotFound.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
