<template>
  <div id="app" class="nav-fixed" :class="{ 'sidenav-toggled': sidebarState }">
    <NavBar />

    <div id="layoutSidenav">
      <div id="layoutSidenav_nav">
        <SideBar />
      </div>
      <div id="layoutSidenav_content">
        <main>
          <div v-if="notificationPermission && notificationPermission!=='granted'" class=" px-3 py-2 mb-2" style="background-color: #FD9800; display:none">
            <div class="text-white d-flex justify-content-center" style="font-weight: 500; font-size: 14px;">
              <div v-if="notificationPermission==='default'">To start receiving new order alerts, click "Allow" when prompted by your browser.&emsp;</div>
              <div v-else>To start receiving new order alerts, enable notifications in your browser settings.&emsp;</div>
              <div @click="onRequestNotificationPermission()" style="text-decoration: underline; cursor: pointer;" v-if="notificationPermission==='default'">Enable Notifications</div> </div>
          </div>
          <div class="py-5"><router-view /></div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import SideBar from '@/components/SideBar.vue'
import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'
import orderService from '@/services/order.service'

const firebaseConfig = {
  apiKey: 'AIzaSyDFkDriVc9rbHQDrm0P5LuPFbQFKAS7SKE',
  authDomain: 'seehay-23ba1.firebaseapp.com',
  projectId: 'seehay-23ba1',
  storageBucket: 'seehay-23ba1.appspot.com',
  messagingSenderId: '112630921012',
  appId: '1:112630921012:web:0ea8b6fa541cbdfbe3d761',
  measurementId: 'G-4F4XN52J3F'
}
// Initialize Firebase
const app = initializeApp(firebaseConfig)
const messaging = getMessaging(app)

export default {
  name: 'Auth',
  data: () => ({
    notificationPermission: ''
  }),
  components: {
    NavBar,
    SideBar
  },

  computed: {
    sidebarState: {
      get () {
        return this.$store.getters.sidebarState
      },
      set (v) {
        return this.$store.commit('toggleSidebarState', v)
      }
    }
  },
  mounted () {
    // TBC
    // this.requestNotificationPermission()
  },
  methods: {
    async requestNotificationPermission () {
      try {
        // Check if the browser supports notifications
        const permission = await Notification.requestPermission()
        this.notificationPermission = permission
        if (permission === 'granted') {
          console.log('Notification permission granted.')
          this.handleGetToken()
          onMessage(messaging, (payload) => {
            console.log('Message received. ', payload)
            if (payload.notification) {
              this.makeToast('success', payload.notification?.title, payload.notification?.body)
              setTimeout(() => {
                orderService.getAllOrder().then((res) => {
                  this.$store.commit('setOrderList', res.data)
                })
              }, 500)
            }
          })
        }
      } catch (error) {
        console.error('Error requesting notification permission:', error)
      }
    },
    async handleGetToken () {
      try {
        const token = await getToken(messaging, {
          vapidKey:
            'BCEsZsi3FsBdzt4qIoIxka-0GoldG-1gjotClrZF0Anz5yceHUcYxDOdPwSI-oK2R9YX5BHVZOeBgwv4sF2KdCg'
        })
        console.log(token)
      } catch (error) {
        console.error('An error occurred while retrieving token:', error)
      }
    },
    makeToast (variant = null, title = 'Title', message = 'Message!') {
      this.$bvToast.toast(message, {
        title: title,
        autoHideDelay: 3000,
        variant: variant,
        solid: true
      })
    },
    onRequestNotificationPermission () {
      if (!('Notification' in window)) {
        console.error('This browser does not support desktop notifications.')
        return
      }
      if (Notification.permission === 'granted') {
        console.log('Notifications are already allowed.')
      } else if (Notification.permission !== 'denied') {
        Notification.requestPermission()
          .then((permission) => {
            this.notificationPermission = permission
            console.log('Promise resolved: ' + permission)
          })
          .catch((error) => {
            console.log('Promise was rejected')
            console.log(error)
          })
      }
    }
  }
}
</script>
