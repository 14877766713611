import http from './http-common'

class OrderService {
  async getAllOrder () {
    return (await http.get('/staff/order/all')).data
  }

  async approveOrRejectFps (id, isAccepted) {
    return (
      await http.put(`/staff/order/${id}/fps-approval?isAccepted=${isAccepted}`)
    ).data
  }

  async updateOrderStatus (id, status) {
    return (
      await http.put(`/staff/order/${id}/update-status?orderStatus=${status}`)
    ).data
  }

  async getOrderDetails (id) {
    return (await http.get(`/staff/order/${id}`)).data.data
  }

  async getBranchDetails (id) {
    return (await http.get(`/staff/branches/${id}`)).data.data
  }

  async assignDriverToOrder (orderId, data) {
    return await http({
      method: 'POST',
      url: `/staff/order/${orderId}/assign-driver`,
      data: data,
      headers: {
        'Content-Type':
          'multipart/form-data;boundary = ' + new Date().getTime()
      }
    })
  }

  async setDeliveryFee (data) {
    return await http({
      method: 'POST',
      url: '/staff/order/set-delivery-fee',
      data: data,
      headers: {
        'Content-Type':
          'multipart/form-data;boundary = ' + new Date().getTime()
      }
    })
  }

  async setFreeDeliveryThreshold (threshold) {
    return (
      await http.post(
        `/staff/order/set-free-delivery-threshold?threshold=${threshold}`
      )
    ).data
  }

  async getFreeDeliveryThreshold () {
    return (await http.get('/staff/order/get-free-delivery-threshold')).data
      .data
  }

  async getDeliveryFee () {
    return (await http.get('/staff/order/get-delivery-fee-from-db')).data.data
  }

  async getDashboardStatistics (startDate, endDate) {
    const start = startDate.replaceAll('-', '/')
    const end = endDate.replaceAll('-', '/')
    return (await http.get(`/staff/dashboard?startDate=${start}&endDate=${end}`)).data.data
  }

  async getRefundDetails (orderId) {
    return (await http.get(`/staff/order/${orderId}/refund-details`)).data.data
  }

  async refundOrder (orderId, amount, refundMethod) {
    const formData = new FormData()
    formData.append('amount', amount)
    formData.append('refundMethod', refundMethod)
    return await http({
      method: 'POST',
      url: `/staff/order/${orderId}/refund-order`,
      data: formData,
      headers: {
        'Content-Type':
          'multipart/form-data;boundary = ' + new Date().getTime()
      }
    })
  }

  async refundCancelOrder (orderId, refundMethod) {
    const formData = new FormData()
    formData.append('refundMethod', refundMethod)
    return await http({
      method: 'POST',
      url: `/staff/order/${orderId}/cancel-order`,
      data: formData,
      headers: {
        'Content-Type':
          'multipart/form-data;boundary = ' + new Date().getTime()
      }
    })
  }

  async partialRefundOrder (orderId, payload) {
    return await http.post(
      `/staff/order/${orderId}/refund-order?amount=${payload.amount}&refundMethod=${payload.refundMethod}`,
      payload.orderCancellationDTOs
    )
  }

  async getOrderStatusTimeUpdate (orderId) {
    return (await http.get(`/staff/order/${orderId}/get-order-status-update`)).data.data
  }

  async setNewAddressForOrder (orderId, newAddress) {
    const formData = new FormData()
    formData.append('newAddress', newAddress)
    return await http({
      method: 'POST',
      url: `/staff/order/${orderId}/set-new-address`,
      data: formData,
      headers: {
        'Content-Type':
          'multipart/form-data;boundary = ' + new Date().getTime()
      }
    })
  }
}

export default new OrderService()
