<template>
  <div>
    <nav class="topnav navbar navbar-expand navbar-dark bg-brown">
      <a class="navbar-brand" href="/" v-show="!sidebarState">
        <div class="d-none d-lg-flex align-items-center px-3">
          <img class="img--logo" src="../assets/logo.png" alt="SeeHay Logo" />
          <span class="h4 font-weight-bold text-brown mb-0 ml-2">See Hay</span>
        </div>
      </a>

      <button class="btn btn-link btn-sm mx-2" @click="sidebarState = !sidebarState">
        <b-icon icon="list" font-scale="2" class="text-white"></b-icon>
      </button>
      <div class="d-flex align-items-center d-lg-none">
        <img class="img-fluid rounded-pill shdow " width="48" alt="SeeHay Logo" src="../assets/logo.png" />
        <span class="d-none d-sm-inline h4 font-weight-bold text-white mb-0 ml-2">See Hay</span>
      </div>

      <!-- Navbar-->
      <ul class="navbar-nav ml-auto">
        <b-dropdown variant="link" toggle-class="text-white text-decoration-none">
          <template #button-content>
            <b-avatar class="border-3 border-white mr-2"></b-avatar>
            <span class="text-uppercase">{{username}}</span>
          </template>
          <b-dropdown-item @click="showChangePasswordModal()">更改密碼</b-dropdown-item>
          <b-dropdown-item @click="logout">登出</b-dropdown-item>
        </b-dropdown>
      </ul>
    </nav>

    <b-modal ref="changePasswordModal" centered size="md" no-close-on-backdrop hide-header hide-footer>
      <b-alert show variant="danger" v-if="veeErrors.all().length">
        <ul class="list-unstyled mb-0">
          <li v-for="error in filterVeeErrors(veeErrors.all())" :key="error">{{ error }}</li>
        </ul>
      </b-alert>

      <b-form @submit.stop.prevent="onSubmitChangePassword" class="p-3">
        <div class="mb-5">
          <h5 class="text-brown">更改密碼</h5>
          <p class="text-muted">請注意密碼需要至少8個由英文字母及數字組成，並需包括一個大寫字母，一個細寫字母。</p>
        </div>

        <div class="mb-4">
          <b-row class="mb-3">
            <b-col sm="4">
              <label for="previousPassword" class="text-brown">舊密碼 <span class="text-danger">*</span></label>
            </b-col>
            <b-col sm="8">
              <b-form-input
                id="previousPassword"
                type="password"
                class="rounded-0"
                name="previousPassword"
                v-model="formControl.previousPassword"
                v-validate="{ required: true, max: 100 }"
                :state="validateState('previousPassword')"
                aria-describedby="input-previousPassword-live-feedback"
                data-vv-as="舊密碼"
              ></b-form-input>
            </b-col>
          </b-row>

          <b-row class="mb-3">
            <b-col sm="4">
              <label for="proposedPassword" class="text-brown">新密碼 <span class="text-danger">*</span></label>
            </b-col>
            <b-col sm="8">
              <b-form-input
                id="proposedPassword"
                type="password"
                class="rounded-0"
                name="proposedPassword"
                v-model="formControl.proposedPassword"
                v-validate="{ required: true, min: 8, max: 100, regex: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).*$/ }"
                :state="validateState('proposedPassword')"
                aria-describedby="input-proposedPassword-live-feedback"
                data-vv-as="新密碼"
              ></b-form-input>
            </b-col>
          </b-row>

          <b-row class="mb-3">
            <b-col sm="4">
              <label for="confirmPassword" class="text-brown">確認新密碼 <span class="text-danger">*</span></label>
            </b-col>
            <b-col sm="8">
              <b-form-input
                id="confirmPassword"
                type="password"
                class="rounded-0"
                name="confirmPassword"
                v-model="formControl.confirmPassword"
                v-validate="{ required: true, confirmed: formControl.proposedPassword, min: 8, max: 100 }"
                :state="validateState('confirmPassword')"
                aria-describedby="input-confirmPassword-live-feedback"
                data-vv-as="確認新密碼"
              ></b-form-input>
            </b-col>
          </b-row>
        </div>

        <div class="d-flex align-items-center justify-content-end">
          <b-button @click="hideChangePasswordModal()" class="btn btn-warning rounded-10px px-3 px-lg-5 text-white mr-2 me-lg-4">取消</b-button>
          <b-button :disabled="!hasVeeErrors" type="submit" variant="primary" class="rounded-10px px-3 px-lg-5">儲存</b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { getAuthProfile, getAuthLogout, changePassword } from '@/services/auth.service'

export default {
  name: 'Navbar',

  data: () => ({
    username: '',
    formControl: {
      previousPassword: '',
      proposedPassword: '',
      confirmPassword: ''
    }
  }),

  computed: {
    sidebarState: {
      get () { return this.$store.getters.sidebarState },
      set (v) { return this.$store.commit('toggleSidebarState', v) }
    },
    hasVeeErrors: ({ veeErrors }) => veeErrors.all().length === 0
  },

  mounted () {
    this.getUsername()
  },

  methods: {
    getUsername () {
      getAuthProfile().then(
        res => {
          if (res.data.success) {
            this.username = res.data.data.name
          }
        },
        err => {
          console.log(err)
        }
      )
    },
    logout () {
      getAuthLogout()
    },
    showChangePasswordModal () {
      this.$refs.changePasswordModal.show()
    },
    hideChangePasswordModal () {
      this.resetformControl()
      this.$refs.changePasswordModal.hide()
    },
    validateState (ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref)
      }
      return null
    },
    filterVeeErrors (veeErrors = []) {
      return veeErrors.filter((element, index) => {
        return veeErrors.indexOf(element) === index
      })
    },
    onSubmitChangePassword () {
      this.$validator.validateAll().then(result => {
        if (!result) {
          return
        }

        changePassword(this.formControl).then(
          res => {
            console.log(res.data)
            if (res.data.success !== true) {
              if (res.data.message?.includes('Incorrect username or password')) {
                this.makeToast('danger', '更改密碼', '舊密碼不正確')
              } else {
                this.makeToast('danger', '更改密碼', '更改密碼不成功')
              }
            } else {
              this.hideChangePasswordModal()
              this.makeToast('success', '更改密碼', '更改密碼成功')
            }
          },
          err => {
            console.log(err)
            this.makeToast('danger', 'ERROR', err)
          }
        )
      })
    },
    makeToast (variant = null, title = 'Title', message = 'Message!') {
      this.$bvToast.toast(message, {
        title: title,
        autoHideDelay: 3000,
        variant: variant,
        solid: true
      })
    },
    resetformControl () {
      this.formControl = {
        previousPassword: '',
        proposedPassword: '',
        confirmPassword: ''
      }
    }
  }
}
</script>
