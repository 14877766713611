import http from './http-common'

export async function getAuthProfile () {
  return await http.get('/staff/current')
}

export async function getAuthLogout () {
  window.location.href = '/seehay/api/v1/oauth2/logout'
}

export async function changePassword (data) {
  return await http.post('/staff/change-password', data)
}
